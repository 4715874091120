import React from 'react';
import { Container, 
        Grid, 
        Paper,
        Accordion,
        AccordionSummary,
        Typography,
        TextField,
        AccordionDetails,
        List,
        ListItem,
        ListItemIcon,
        ListItemText,
        Box,
        Breadcrumbs,
        Link  } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Fastfood as FastfoodIcon, 
        ExpandMore as ExpandMoreIcon,
        Home as HomeIcon
        } from '@material-ui/icons';

import _ from "lodash";
import CardRecipe from './cardrecipe';
import AdSense from 'react-adsense'


const Search=(props)=>{
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const [recipes,setRecipes] = React.useState([]);
    const [recipe,setRecipe] = React.useState([]);    
    const [show,setShow] = React.useState(false);

    //const handleShowFilter=()=>{
    //    setShow(false);
    //}
    
    const getRecipe = (id) =>{
        (async () => {

            let response = await fetch(process.env.REACT_APP_API_URL + "/recipe/" + id, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json;charset=utf-8",
                },
              });
            
            const theRecipe = await response.json();            
            setRecipe(theRecipe);
            
        })();
    }

    const haddleShowRecipe=(event,id)=>{
        event.preventDefault();
        getRecipe(id);
        setShow(true);
    }

  
    const readValue=(event,values,reason)=>{
        const keys = [];
        if ( reason === "clear"){
            setShow(false);
            setRecipes([]);
        }
        else{
            for (let idx=0; idx<values.length;idx++){
                keys.push(values[idx].id);
            }
        
            if (keys.length > 0) searchIngredients(keys);
        }
    }

    const searchIngredients=(keys)=>{    
        (async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + '/recipe/search',{
                method:"POST",
                headers:{
                    "Content-Type": "application/json;charset=utf-8", 
                },
                body:JSON.stringify(keys)
            });
            
            //await sleep(1e3); 
            const theRecipes = await response.json();            
            
            const groupByMatch = _.groupBy(theRecipes,"match");
            const ranges={90:[],70:[],50:[],30:[],10:[]};
            let floor=0;
            
            let items=[];
            let step=10;
            for (let value2 in groupByMatch){
                floor=Math.floor(value2/step) * step
                            items = groupByMatch[value2]  
                if (!_.isEmpty(items)){
                if (typeof(ranges[floor]) != "undefined"){
                    ranges[floor] = ranges[floor].concat(items);
                }
                else if (typeof(ranges[floor-step]) != "undefined"){
                    ranges[floor-step] = ranges[floor-step].concat(items);
                }
                }
            }

           buildRecipesAccordion(ranges);
        })();
    
    }


    
    const buildRecipesAccordion=(recipes)=>{
        let element=[];
        const list=[90,70,50,30,10];
        let theRecipes =[];
        let range = 0;
        let idAcc="";
        let idAccContent="";
        let items = [];
      
        for (let idx=0; idx < list.length; idx++){
            range = list[idx];
            theRecipes = recipes[range];            
            
            items = theRecipes.map((item)=>{
                return (<ListItem button>
                  <ListItemIcon>
                    <FastfoodIcon style={{color:"white"}} />
                  </ListItemIcon>
                  <ListItemText style={{color:"white"}} primary={item.name + "(" + item.match + "%)"} onClick={(e)=>haddleShowRecipe(e,item.id)}/>
                </ListItem>
                )
            });

            idAcc="panel" + idx + "a-header";
            idAccContent="panel" + idx + "a-content";
          //  if (!_.isEmpty(theRecipes)){
                element.push(
                    <Accordion style={{backgroundColor:"#ffc400"}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={idAccContent}
                            id={idAcc}
                         >
                            <Typography style={{color:"white",fontWeight:"500"}}>{range}% - {range+10}% Match({theRecipes.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <List component="nav" aria-label="main mailbox folders">
                                {items}
                            </List>
                         </AccordionDetails>
                    </Accordion>
                );
           // }   
            
        }   
        setRecipes(element) ;  
    }

    React.useEffect(() => {
        let active = true;
    
        if (!loading) {
          return undefined;
        }
    
        (async () => {
          const response = await fetch(process.env.REACT_APP_API_URL+'/ingredient');
          
          //await sleep(1e3); 
          const ingredients = await response.json();
    
          if (active) {
            setOptions(Object.keys(ingredients).map((key) => ingredients[key]));
          }
        })();
    
        return () => {
          active = false;
        };
      }, [loading]);
    
      React.useEffect(() => {
        if (!open) {
          setOptions([]);
        }
      }, [open]);
    
    

    return (
        <div style={{margin:5}}>
            <br/>
        <Container>
            <Paper elevation={3} style={{padding:10}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        <Typography variant="h5" color="textPrimary">
                            <HomeIcon/>Home
                        </Typography>                                            
                    </Link>
                    <Typography variant="h5" color="textPrimary">
                        Search
                    </Typography>                    
                </Breadcrumbs>
            <Grid container 
                spacing={3}
                style={{ minHeight: '90vh' }}
            >
                <Grid item xs>
                    {recipes.length===0?
                        <Box maxWidth="sm"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100vh"
                            style={{backgroundColor:"#ffc400",borderRadius:20}}
                            >
                                <Typography variant="h4" component="h5" style={{color:"white"}} >
                                    <pre style={{ fontFamily: 'inherit' }}>
                                        As You Enter Ingredients
                                    </pre>
                                    <pre style={{ fontFamily: 'inherit' }}>
                                        Search Results Will Appear
                                    </pre>
                                </Typography>                        
                        </Box>
                        :recipes}                   
                </Grid>
                <Grid item xs>
                   
                    <Autocomplete
                        id="ingredients"
                        onChange={readValue}
                        style={{ width: 300 }}
                        multiple
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={options}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Ingredients"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}                            
                            />
                        )}                        
                    />
                    {show?<CardRecipe data={recipe}></CardRecipe>:""}
                </Grid>        
            </Grid>
      
            </Paper>
        </Container>
        <AdSense.Google
    client='ca-pub-1482063855283742'
    slot='1482063855283742'
  />
        </div>
    );
}
export default Search;