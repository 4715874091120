import React from 'react';
import { Container, 
        Grid, 
        Paper,
        Typography,
        Breadcrumbs,
        FormControl,
        InputLabel,
        TableContainer,
        TableCell,
        TableHead,
        Table,
        TableRow,
        TableBody,
        OutlinedInput,
        TextField,
        Button,
        IconButton,
        Snackbar,
        Link  } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Alert from '@material-ui/lab/Alert';
import {        
        Home as HomeIcon,
        } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import { amber } from '@material-ui/core/colors';
import AdSense from 'react-adsense'

const useStyles = makeStyles({
  container: {
    maxHeight: 400,
  },
});

const theme = createMuiTheme({
  palette: {
    primary: { light: amber['A400'], main: amber[500], dark: amber[700] }
  },
});

const Recipe=(props)=>{
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const [openUnit, setOpenUnit] = React.useState(false);
  const [optionsUnit, setOptionsUnit] = React.useState([]);
  const loadingUnit = openUnit && optionsUnit.length === 0;
  const [show, setShow] = React.useState(false);
  const [recipe,setRecipe] = React.useState({name:"",email:"",image:"",mime:"",serving:1,ingredients:[],steps:[]});
  const [ingredient,setIngredient] = React.useState({name:"",id:"",unit:"",unit_id:"",amount:""});
  const [ingredients,setIngredients] = React.useState([]);
  const [step,setStep] = React.useState("");
  const [steps,setSteps] = React.useState([]);
  const [message,setMessage] = React.useState("");
  const [severity,setSeverity] = React.useState("");
  const [image,setImage] = React.useState({preview:'',raw:'',name:"",type:""});  
  const [showImage, setShowImage] = React.useState(false);
  const [valueStateIngredient,setValueStateIngredient] =React.useState("");

  const onInputChange=(event,value,reason)=>{
    console.log("reason",reason);
    setIngredient((prev) => ({
      ...prev,
      "name": value
    }));
  }

 

  const onInputChangeUnit=(event,value)=>{
    setIngredient((prev) => ({
      ...prev,
      "unit": value
    }));
  }

  const initialState = {name:"",email:"",image:"",mime:"",serving:1,ingredients:[],steps:[]};

  const classes = useStyles();
  // Units
  React.useEffect(() => {
    let activeUnit = true;
    if (!loadingUnit) {
      return undefined;
    }

    (async () => {
      const response = await fetch(process.env.REACT_APP_API_URL+'/unit');
      
      //await sleep(1e3); 
      const units = await response.json();

      if (activeUnit) {
        setOptionsUnit(Object.keys(units).map((key) => units[key]));
      }
    })();

    return () => {
      activeUnit = false;
    };
  }, [loadingUnit]);

  React.useEffect(() => {
    if (!openUnit) {
      setOptionsUnit([]);
    }
  }, [openUnit]);

// Ingredients
  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await fetch(process.env.REACT_APP_API_URL+'/ingredient');
      
      //await sleep(1e3); 
      const ingredients = await response.json();
      
      if (active) {
        setOptions(Object.keys(ingredients).map((key) => ingredients[key]));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);
  };

  const handleChange = ({ target }) => {
    const {name, value} = target;
  //  if (value === "") return;
    if (name === "step"){
      setStep(value);
    }
    else if (name === "unit" || name === "amount"){
      setIngredient((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    else{
      setRecipe((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };


  const addIngredient=()=>{
    const result = ingredients.find(e => e.name === ingredient.name);
    if ( ! result ){
      setIngredients(prevIngredients => [...prevIngredients, ingredient])
    }

    document.getElementsByClassName("MuiAutocomplete-clearIndicator")[0].click();
    document.getElementsByClassName("MuiAutocomplete-clearIndicator")[1].click();
    setIngredient({"amount":""});
   // document.getElementById("ingredient").value="";
    //document.getElementById("amount").value="";
    //document.getElementById("unit").value="";
  }

  const addStep =() =>{
    if ( step.trim() === "" ) return;
    setSteps(prevSteps=>[...prevSteps,step]);
    setStep('');
  }

  const deleteIngredient=(index)=>{
     setIngredients(ingredients.filter((item,idx)=>(
      idx !== index
     )));
  }

  const deleteStep=(index)=>{

    setSteps(steps.filter((item,idx)=>(
      idx !== index
    )));
  }

 
  const handleAttach=(event)=>{
    const formData = new FormData();
    const fileField = event.target.files[0]; // document.querySelector('input[type="file"]');
    formData.append("avatar", fileField);
    
    if (event.target.files.length) {
      setImage({
        preview: URL.createObjectURL(fileField),
        raw: fileField
      });
    }


    fetch(process.env.REACT_APP_API_URL + "/upload", {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        setRecipe((prev) => ({
          ...prev,
          ["image"]: result.path,
          "mime":result.mimetype
        }));
        console.log("Success:", result);
        setShowImage(true);
        
        setMessage("Image was uploaded!");
        setSeverity("success");
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage("Image cannot uploaded!");
        setSeverity("error");
      });
  }

  const saveRecipe = () =>{
    recipe.ingredients = ingredients;
    recipe.steps = steps;
    setRecipe(recipe);

    console.log("Recipe", JSON.stringify(recipe));
    (async () => {
      let response = await fetch(process.env.REACT_APP_API_URL + "/recipe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(recipe),
      });
      const theRecipe = await response.json();
      if ( typeof(theRecipe._id) !== "undefined" && theRecipe._id !== "" ){
        setShow(true);
        setMessage("Recipe was added!");        
        setSeverity("success");
        window.location.reload();
      }
    setRecipe(initialState);
    setIngredients([]);
    setSteps([]);
    })();
}
  
    return (
        <div >
          <br/>
          
            <Container  >
                <Paper elevation={3} style={{padding:10}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/" >
                            <Typography variant="h5" color="textPrimary">
                                <HomeIcon/>Home
                            </Typography>                                            
                        </Link>
                        <Typography variant="h5" color="textPrimary">
                            Submit Recipe
                        </Typography>                    
                    </Breadcrumbs>
                    <Snackbar open={show} autoHideDuration={3000} onClose={handleClose} >
                      <Alert  severity={severity}>
                        {message}
                      </Alert>
                    </Snackbar>
                    <form autoComplete="off">
                  <Grid container 
                    spacing={3}                   
                  >
                    <Grid item md={4}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel htmlFor="outlined-adornment-name">Recipe Name</InputLabel>
                      <OutlinedInput
                        id="name"
                        name="name"
                        label="Recipe Name"
                        onChange={handleChange}
                        value={recipe.name}
                      />
                    </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="component-outlined">Email</InputLabel>
                        <OutlinedInput 
                          id="email" 
                          type="email" 
                          name="email" 
                          label="Email" 
                          value={recipe.email}
                          onChange={handleChange} />
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                        <FormControl variant="outlined" fullWidth required>                          
                          <TextField
                            id="serving"
                            name="serving"
                            label="Serving"
                            type="number"
                            variant="outlined"
                            required
                            InputLabelProps={{
                                shrink: true,
                                min:1,
                                max:999
                            }}
                            onChange={handleChange}
                            value={recipe.serving}
                          />
                        </FormControl>
                    </Grid>   
                     
                    <Grid item md={4}>
                      <FormControl variant="outlined" fullWidth required>
                        
                        <Autocomplete
                          id="ingredient"
                          name="ingredient"
                          value={ingredient.id}
                           
                          freeSolo
                          onInputChange={onInputChange}                          
                          style={{ width: 300 }}
                          open={open}
                          required
                          onOpen={() => {
                              setOpen(true);
                          }}
                          onClose={() => {
                              setOpen(false);
                          }}
                          
                          getOptionSelected={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name}
                          options={options}
                          loading={loading}
                          renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Ingredients"
                            variant="outlined"
                            
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                                ),
                            }}
                            />
                        )}
                      />
                      </FormControl>
                    </Grid>           
                    <Grid item md={4}>
                    <FormControl variant="outlined" fullWidth required>                          
                          <TextField
                            id="amount"
                            name="amount"
                            label="Amount"
                            type="number"
                            variant="outlined"
                            required
                            value={ingredient.amount}
                            InputLabelProps={{
                                shrink: true,
                                min:1,
                                max:999,
                                step:0.1
                            }}
                            onChange={handleChange}
                           
                          />
                        </FormControl>
                    </Grid> 
                    <Grid item md={3}>
                      
                    <FormControl variant="outlined" fullWidth required>
                        
                        <Autocomplete
                          id="unit"
                          name="unit"
                          value={ingredient.unit_id}
                          freeSolo
                          onInputChange={onInputChangeUnit}                          
                          style={{ width: 300 }}
                          open={openUnit}
                          required
                          onOpen={() => {
                              setOpenUnit(true);
                          }}
                          onClose={() => {
                              setOpenUnit(false);
                          }}
                          
                          getOptionSelected={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name}
                          options={optionsUnit}
                          loading={loadingUnit}
                          renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Unit"
                            variant="outlined"
                           
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                <React.Fragment>
                                    {loadingUnit ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                                ),
                            }}
                            />
                        )}
                      />
                      </FormControl>
                      
                    </Grid>
                    <Grid item md={1}>
                      <IconButton 
                        disabled={(recipe.name !== ""  && parseInt(recipe.serving) > 0 )?false:true} 
                        color="primary" 
                        aria-label="add ingredient to recipe" 
                        onClick={addIngredient}>
                        <AddCircleOutlineIcon />
                      </IconButton>    
                    </Grid>
                    <Grid item md={11}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel htmlFor="component-outlined">Step</InputLabel>
                        <OutlinedInput 
                          id="step"  
                          name="step"
                          label="Step"
                          onChange={handleChange}
                          value={step}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={1}>
                    <IconButton disabled={ingredients.length>0?false:true} color="primary" aria-label="add step to recipe" onClick={addStep}>
                        <AddCircleOutlineIcon />
                      </IconButton>    
                    </Grid>
                    <Grid item md={6}>
                      <TableContainer component={Paper} className={classes.container}>
                        <Table  stickyHeader  aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                  <h4>Ingredients</h4>
                              </TableCell>
                              <TableCell align="right"><h4>Action</h4></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ingredients.map((row,index) => (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  {row.amount} {row.unit} of {row.name}
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton  aria-label="delete ingredient" onClick={() => deleteIngredient(index)}>
                                      <DeleteIcon />
                                    </IconButton>    
                                  </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>                  
                    <Grid item md={6}>
                      <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell><h4>Steps</h4></TableCell>
                              <TableCell align="right"><h4>Action</h4></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {steps.map((row,index) => (
                              <TableRow key={index+1}>
                                <TableCell component="th" scope="row">
                                  {index+1}.- {row}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton  
                                      aria-label="delete step" onClick={() => deleteStep(index)}>
                                      <DeleteIcon />
                                    </IconButton>    
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid item md={12}>
                         {showImage?<img  className="img-border" id="imgThumbnail" src={image.preview} alt="Recipe"/>:""}
                    </Grid>
                  </Grid>      
                  </form>
                  <Grid container 
                    spacing={3}                   
                  >
                     <Grid item md={6}>
                    <input
                        accept="image/*"
                        style={{display:"none"}}
                        id="contained-button-file"
                        onChange={handleAttach}
                        type="file"
                      />
                       
                      <label htmlFor="contained-button-file">
                      <ThemeProvider theme={theme}>
                        <Button 
                          disabled={steps.length>0 && ingredients.length>0?false:true}
                          variant="contained" 
                          color="primary" 
                          fullWidth
                          startIcon={<CloudUploadIcon />}
                          component="span">
                          Upload
                        </Button>
                        </ThemeProvider>
                      </label>
                    </Grid>
                    <Grid item md={6}>
                      <Button 
                        disabled={steps.length>0 && ingredients.length>0?false:true}
                        fullWidth
                        variant="contained" 
                        color="primary"
                        className="float-right rounded"
                        onClick={saveRecipe}
                      >
                          Submit
                      </Button>
                    </Grid>
                    </Grid> 
                </Paper>
            </Container>
            <AdSense.Google
    client='ca-pub-1482063855283742'
    slot='1482063855283742'
  />
        </div>
    );
}
export default Recipe;