import React from 'react';
import { 
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Avatar,
  Link,
  Container,
  Paper,
  Breadcrumbs,
  } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import 'fontsource-roboto';
import {useLocation} from "react-router-dom";
import {        
  Home as HomeIcon,
  } from '@material-ui/icons';
import AdSense from 'react-adsense'

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: "70%",
      minWidth: 275,
      textAlign:'left',
      minHeight:"400",
      marginTop: theme.spacing(5),
      margin:'auto',
      flexDirection: 'column',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: yellow[700],
    },
    list:{
      margingLeft:5,
      paddingLeft:5,
      textAlign:'left'
    },
  }));

 

const ViewRecipe=(props)=>{
    const classes = useStyles();
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const [recipe,setRecipe] = React.useState({});


    const loadRecipe=(event)=>{
      fetch(process.env.REACT_APP_API_URL + "/recipe/" + id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((result) => {
          setRecipe(result);
          console.log("Success:", result);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    React.useEffect(() => {
      loadRecipe();
      // eslint-disable-next-line
    }, []);
    

    const renderIngredients=()=>{
      let theIngredients = [];
      if ( recipe !== null && typeof(recipe.ingredients) !== "undefined" ){
        theIngredients = recipe.ingredients;
      }

      return <ul>
        {
          theIngredients.map((ingredient,index) =>(
            <li className={classes.list}>{ingredient.amount} {ingredient.unit} {ingredient.name}</li>
          ))
        }
      </ul>

    }

    const renderSteps=()=>{
      let steps =[];
      if ( recipe !== null && typeof(recipe.steps) !== "undefined" ){
          steps = recipe.steps;
      }
      
      return <ol>
          {
              steps.map((item,index)=>(
                  <li className={classes.list}>{item}</li>
              ))
          }
          </ol>;
  }

    return (
      <div style={{margin:5}}>
      <br/>
      <Container>
        <Paper elevation={3} style={{padding:10}}>
          <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/" >
                  <Typography variant="h5" color="textPrimary">
                      <HomeIcon/>Home
                  </Typography>                                            
              </Link>
              <Typography variant="h5" color="textPrimary">
                  View Recipe
              </Typography>                    
          </Breadcrumbs>

      

      <Card className={classes.root}>
         <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                R
              </Avatar>
            }
            title={recipe.name}
            subheader=""
          />
                                
        <CardMedia
          className={classes.media}
          image={process.env.REACT_APP_API_URL + "/get-image/" + recipe.image }
          title={recipe.name}
        />
                       
        <CardContent>
          <Typography variant="h4" gutterBottom>
              {recipe.name}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Ingredients
          </Typography>
          <Typography paragraph>
            {
              renderIngredients()
            }
          </Typography>
          <Typography variant="h6" gutterBottom>
                                Steps
          </Typography>
          <Typography paragraph>
                                {
                                    renderSteps()
                                }
                                </Typography>                            
                        </CardContent>
                        </Card>
        </Paper>                                  
      </Container>

          <AdSense.Google
        client='ca-pub-1482063855283742'
        slot='1482063855283742'
      />
      </div>
    );
}

export default ViewRecipe;