import { createMuiTheme } from "@material-ui/core/styles";

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fec400"
    },
    secondary: {
      main: "#0f0"
    }
  },
  /*
  typography: {
    fontFamily: "Comic Sans MS",       
    body2: {
      fontFamily: "Times New Roman",
      fontSize: "21.1rem"

    }
  },
  */
  shape: {
    borderRadius: 20
  },
  //spacing: 8,
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "green"
      }
    },
   // MuiInputLabel: {
   //   root: {
    //    backgroundColor: "yellow"
     // }
    //},
    MuiTextField: {
      root: {}
    },
     MuiButton: {
      root: {
        textTransform: "none",
        //padding: "20px"
      },
      label:{
          fontSize:"14px"
          ,color:"#74503b"
          ,fontWeight:"bold"
      }
    },
    MuiAccordion:{
      roo:{
        backgroundColor:"#74503b"
      }
    }
    /*
    ,MuiBox :{
      root:{
        borderRadius: 20
        ,color:"#74503b"
      }
    }
    */
  },
  props: {
    MuiButton: {
      disableRipple: true,
      variant: "contained",
      color: "primary"
    },
    MuiCheckbox: {
      disableRipple: true
    },
    MuiTextField: {
      variant: "filled",
      InputLabelProps: {
        shrink: true
      }
    },
    MuiPaper: {
      elevation: 12
    },
    MuiCard: {
      elevation: 12
    }
  }
});