import React from 'react';
import { 
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Avatar,
  Link
  } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import 'fontsource-roboto';
import AdSense from 'react-adsense'

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
      textAlign:'left'
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    avatar: {
      backgroundColor: yellow[700],
    },
    list:{
      margingLeft:5,
      paddingLeft:5,
      textAlign:'left'
    },
  }));

 

const CardRecipe=(props)=>{
    const classes = useStyles();
  
    let recipe = props.data;
    let linkRecipe = "/view?id=" +  recipe.id;
  
    const renderIngredients=()=>{
      let ingredients = [];
      if ( recipe !== null && typeof(recipe.ingredients) !== "undefined" ){
        ingredients = recipe.ingredients;
      }

      return <ul>
        {
          ingredients.map((ingredient,index) =>(
            <li className={classes.list}>{ingredient.amount} {ingredient.unit} {ingredient.name}</li>
          ))
        }
      </ul>

    }

    const renderSteps=()=>{
      let steps =[];
      if ( recipe !== null && typeof(recipe.steps) !== "undefined" ){
          steps = recipe.steps;
      }
      
      return <ol>
          {
              steps.map((item,index)=>(
                  <li className={classes.list}>{item}</li>
              ))
          }
          </ol>;
  }

    return (
      <div>
      <Card className={classes.root}>
         <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        title={recipe.name}
        
        />
                                
        <CardMedia
          className={classes.media}
          image={process.env.REACT_APP_API_URL + "/get-image/" + recipe.image }
          title={recipe.name}
        />
                       
        <CardContent>
          <Typography variant="h4" gutterBottom>
            <Link 
              color="inherit" 
              href={linkRecipe}>
              {recipe.name}
            </Link>
            
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Ingredients
                            </Typography>
                            <Typography paragraph>
                                {
                                    renderIngredients()
                                }
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                Steps
                            </Typography>
                                <Typography paragraph>
                                {
                                    renderSteps()
                                }
                                </Typography>                            
                        </CardContent>
                        </Card>
        <AdSense.Google
          client='ca-pub-1482063855283742'
          slot='1482063855283742'
        />
      </div>
    );
}

export default CardRecipe;