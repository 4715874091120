import { Button,Box,Grid } from '@material-ui/core';
import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import refri from "../static/images/GCS_landingLOGO_5-21-20.png";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AdSense from 'react-adsense'

function ImageResized(props){
  const isMobile = props.ismobile;
  if (isMobile){
    return <img src={refri} alt="Gotta Cook Something"/>;
  }
  else{
    return <img width="80%" src={refri} alt="Gotta Cook Something"/>;
    
  }
}


const Home = (props) => {
  const mobile = useMediaQuery('(min-width:600px)');
  return (
    <div>
  <Grid container 
    direcction="column"
    alignItems="center"
    style={{ minHeight: '100vh' }}
    justify="center"
>
  <Grid 
    item md={12}      
  >
      <ImageResized ismobile={mobile}/>
      
    <Box>
      <Grid 
        container  
        spacing={1}
      > 
        <Grid 
          item 
          md={3}
          xs={12}
        ></Grid>
        <Grid 
          item 
          md={2}
          xs={12}
        >
         
          <Button 
            fullWidth
            variant="contained" 
            color="primary"
            startIcon={<SearchIcon />}
            className="float-right"
            href="/search"
          >
              Search Recipes
          </Button>
        </Grid>
        <Grid 
          item 
          md={2}
          xs={12}
        >
        </Grid>
        <Grid 
          item 
          md={2}
          xs={12}
        >
          <Button 
            fullWidth
            variant="contained" 
            color="primary"
            startIcon={<FastfoodIcon />}
            className="float-left"        
            href="/recipe"
          >
             Add Recipes
          </Button>
        </Grid>
        <Grid 
          item md={3}
        ></Grid>
      </Grid> 
    </Box>
  </Grid>
	
</Grid>
  <AdSense.Google
    client='ca-pub-1482063855283742'
    slot='1482063855283742'
  />
</div>
  )
  };

export default Home;
