import './App.css';
import 'fontsource-roboto';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './components/home';
import Search from './components/search';
import Recipe from './components/recipe';
import ViewRecipe from './components/viewrecipe';
import 'fontsource-roboto';
import { theme } from './theme';
import {ThemeProvider}  from '@material-ui/core'; 

function App() {
  return (
    <Router>
    <div className="App">
    <ThemeProvider theme={theme}>
      <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/search" component={Search} />
          <Route path="/recipe" component={Recipe} />
          <Route path="/view" component={ViewRecipe} />
          <Route render={() => <h1>404: page not found</h1>} />
      </Switch>
      </ThemeProvider>
      
    </div>
    </Router>
  );
}

export default App;
